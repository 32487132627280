<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-if="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-else>
      <div class="modal-header d-block">
        <div class="d-flex justify-content-between">
          <h3>Company Details</h3>
          <img src="@/assets/icons/icon-edit.svg" class="opacity-05" alt="Edit" @click="$emit('editCompany', company.id)">
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container mt-3">
          <div class="modal-body-container_title">
            {{ company.name }}
          </div>
          <div class="table">
            <table class="table">
              <tbody>
              <tr>
                <td>Type</td>
                <td>{{ company.type === 'escrow' ? 'Escrow' : 'Title' }}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td style="white-space: pre;">{{ company.address }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ company.state }}</td>
              </tr>
              <tr>
                <td>License No.</td>
                <td>{{ company.licenseNumber }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Contact</div>
          <div class="table">
            <table class="table">
              <tbody>
              <tr>
                <td>Settlement Agent</td>
                <td>{{ company.settlementAgentName ? company.settlementAgentName : 'N/A' }}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{{ company.phone }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{{ company.email }}</td>
              </tr>
              <tr>
                <td>Open Order Email</td>
                <td>{{ company.openOrderEmail ? company.openOrderEmail : 'N/A' }}</td>
              </tr>
              <tr>
                <td>Note</td>
                <td style="white-space: pre;">{{ company.note ? company.note : 'N/A' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-end" v-if="company.isGranted.delete">
        <img src="@/assets/icons/icon-delete.svg" class="opacity-05 cursor-pointer" alt="Attach" @click="deleteCompany">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyDetails",
  props: { id: { type: Number, required: true } },
  data() {
    return {
      loading: true,
      company: {},
      editCompany: false,
    }
  },
  methods: {
    getCompany() {
      this.$http.get(`/api/v1/escrow-companies/show/${this.id}`)
        .then((res) => {
          this.company = res.data
          this.loading = false
        })
    },
    deleteCompany() {
      let confirm = window.confirm('Are you sure you want to delete this company?')
      if (confirm) {
        this.$http.delete(`/api/v1/escrow-companies/delete/${this.id}`)
          .then(() => {
            this.$emit('close', true)
          })
      }
    }
  },
  beforeMount() {
    this.getCompany()
  }
}
</script>
<style type="scss" scoped>
.status {
  margin-left: 10px;
  border: 1px solid #E7E8E8;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #F6F6F6;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 5px 10px;
}
</style>
