<template>
  <div class="page-content-grey">
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <h3 class="page-header-title">Escrow/Title</h3>
        <span class="subtitle d-none d-sm-inline">All escrow and title companies</span>
      </div>
      <div class="page-header-buttons d-flex">
        <a
          :href="`mailto:${escrowCompanyEmails.join(';')}`"
          class="base-button temp text-decoration-none"
          target="_blank"
          :class="{ disabled : escrowCompanyEmails.length == 0}"
          v-if="isGranted('ROLE_SEND_BLAST_ESCROW_EMAIL') || isGranted('ROLE_CEO_ASSISTANT')">Blast Email</a>
        <base-button title="Add New Company" @click-btn="openCompanyForm" action="primary" class="ms-2" />
      </div>
    </div>
    <div class="page-content bg-grey p-normal">
      <div class="d-flex flex-column flex-sm-row mb-3 mb-sm-0 justify-content-between">
        <div class="search-wrapper">
          <img src="@/assets/icons/icon-search.svg" alt="Icon search">
          <input type="text" placeholder="Search" v-model="query">
        </div>
        <div class="d-flex">
          <multiselect v-model="state"
                     @select="getByState"
                     :options="states"
                     :searchable="true"
                     :close-on-select="true"
                     :show-labels="false"
                     :allow-empty="false"
                     placeholder="Select State"/>

          <multiselect v-model="companyType"
                     track-by="value"
                     label="label"
                     @select="getByType"
                     :options="companyTypes"
                     :searchable="false"
                     :close-on-select="true"
                     :show-labels="false"
                     :preselectFirst="true"
                     class="mx-2"/>
        </div>
      </div>
      <div class="">
        <div class="text-center mt-5" v-if="isLoading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="cards-wrapper" v-else>
          <div class="_card" v-for="(company, index) in companyList" :key="index">
            <div class="_card-header d-flex justify-content-between align-items-center">
              <div>
                <span class="type">{{ capitalize(company.type) }}</span>
                <h4 class="title">{{ company.name }}</h4>
              </div>
              <div class="edit-icon cursor-pointer"  v-if="company.isGranted['edit']" @click="openCompanyForm(company.id)">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M16.5 3.5l-13 13v3h3l13-13zM9.5 19.5h11"/>
                  </g>
                </svg>
              </div>
            </div>
            <div class="_card-body">
              <p>
                {{ company.address }} <br>
                {{ company.city }} {{ company.zip }}
              </p>
              <div class="small-tags">
                <span class="license-num">{{ company.licenseNumber ? company.licenseNumber : 'N/A' }}</span>
                <span class="state">{{ company.state ? company.state : 'National' }}</span>
              </div>
              <div class="more-detail-link">
                <a class="hover-link cursor-pointer" @click="selectedCompanyId = company.id">More Details</a>
              </div>
            </div>
          </div>
        </div>
        <status-message class="status-msg status-msg_alert" v-if="companyList.length === 0 && !isLoading" msg="No Escrow yet." />
      </div>
    </div>
    <transition name="slide-fade">
      <CompanyDetails v-if="!companyForm && selectedCompanyId"
                      :id="selectedCompanyId"
                      @close="closeCompanyDetails"
                      @editCompany="openCompanyForm"/>
    </transition>
    <transition name="slide-fade">
      <CompanyForm v-if="companyForm" :id="selectedCompanyId" @close="closeCompanyForm"/>
    </transition>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import CompanyDetails from './CompanyDetails'
import CompanyForm from './CompanyForm'
import StatusMessage from "../../components/StatusMessage";
import BaseButton from '../../components/BaseButton.vue';
export default {
  name: "EscrowCompanies",
  components: {StatusMessage, Multiselect, CompanyDetails, CompanyForm, BaseButton},
  data() {
    return {
      companies: [],
      companyType: null,
      state: null,
      companyTypes: [
        {
          value: null,
          label: 'All Companies'
        },
        {
          value: 'escrow',
          label: 'Escrow'
        },
        {
          value: 'title',
          label: 'Title'
        }
      ],
      states: [],
      timer: null,
      query: '',
      isLoading: true,
      selectedCompanyId: null,
      companyForm: false
    }
  },
  methods: {
    getCompanies() {
      this.$http.get('/api/v1/escrow-companies')
        .then((res) => {
          this.companies = res.data
          this.isLoading = false
        })
        .catch()
    },
    getStates() {
      this.$http.get(`/api/v1/form/states`)
        .then((res) => {
          res.data.states.unshift('All States');
          this.states = res.data.states
          this.state = res.data.states[0]
        })
    },
    getByType(option) {
      this.companyType = option
    },
    getByState(val) {
      this.state = val
    },
    closeCompanyDetails(updateList) {
      if (updateList) {
        this.getCompanies()
      }
      this.selectedCompanyId = null
    },
    openCompanyForm(id) {
      this.companyForm = !this.companyForm
      this.selectedCompanyId = id
    },
    closeCompanyForm(updateData) {
      if(updateData) {
        this.getCompanies()
      }
      this.companyForm = !this.companyForm
      this.selectedCompanyId = null
    },
  },
  computed: {
    escrowCompanyEmails() {
      return this.companies.filter((company) => this.validateEmail(company.email) && company.type == 'escrow').map((company) => {
        return company.email;
      });
    },
    companyList() {
      return this.companies.filter(company => {
        let content = company.name.toLowerCase().includes(this.query.toLowerCase());
        if (this.companyType.value) {
          return content && company.type === this.companyType.value
        } else if (this.state && this.state !== 'All States') {
          return content && (company.state === this.state || company.state === 'Nationwide')
        }

        return content;
      })
    }
  },
  beforeMount() {
    this.getStates()
    this.getCompanies()
    this.$emit('bgColor', )
  }
}
</script>
<style lang="scss" scoped>

.search-wrapper {
  @media (max-width: 568px) {
    max-width: 100%;
  }
}

.page-header {
  border-bottom: 1px solid #E7E8E8;
  padding: 18px 30px 12px 30px;
  .blast-email {
    padding-top: 6px;
    padding-bottom: 6px;

    &.disabled {
      pointer-events: none;
    }
  }
}

.page-content {
  .multiselect__tags {
    min-height: 36px;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 5px 40px 0 8px;
    background-color: #F3F3F3;
  }
  .multiselect__single {
    background-color: #F3F3F3;
    margin-top: 3px;
    margin-bottom: 0;
    font-size: 12px
  }
  .multiselect__select {
    height: 35px;
  }
}
.page-content::v-deep  {
  .multiselect {
    min-height: 36px;
    max-height: 36px;
    min-width: 180px;
    max-width: 180px;
    width: fit-content;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }
    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38,111,229,0.5);
        box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
      }
    }
    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all .3s;
      &:hover {
        border: 1px solid rgba(38,111,229,0.2);
        box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
      }
    }
    &__select {
      min-height: 36px;
      max-height: 36px;
      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }
    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
</style>
